<template>
    <v-card>
        <v-toolbar class="darken-1" color="blue" dark>
            <v-toolbar-title>
                {{branchData.branch.provinceName}} - 
                {{branchData.branch.branchName}} 
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn @click="dialogueClose" dark text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td> Name :</td>
                        <td>{{branchData.branch.branchName}}</td>
                    </tr>
                    <tr>
                        <td>Address :</td>
                        <td>{{branchData.branch.branchAddress}}</td>
                    </tr>
                    <tr>
                        <td> Code :</td>
                        <td>{{branchData.branch.branchCode}}</td>
                    </tr>
                    <tr>
                        <td> Phone Number :</td>
                        <td>{{branchData.branch.branchPhoneNumber}}</td>
                    </tr>
                    <tr>
                        <td> Email :</td>
                        <td>{{branchData.branch.branchEmail}}</td>
                    </tr>
                    <tr>
                        <td> Website :</td>
                        <td>{{branchData.branch.branchWebsite}}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-text>
            <span>Last Transactions</span>
            <component :is="activeComponent" :branchData="branchData"></component>
<!--            <v-simple-table class="log-table">-->
<!--                <template v-slot:default>-->
<!--                    <table v-if="divisionName=='Hotel' || divisionName=='HomeStay'">-->
<!--                        <th class="table-header">-->
<!--                            Check In Date-->
<!--                        </th>-->
<!--                        <th class="table-header">-->
<!--                            Check Out Date-->
<!--                        </th>-->
<!--                        <th class="table-header">-->
<!--                            Checkout Status-->
<!--                        </th>-->
<!--                        <th class="table-header">-->
<!--                            Room Number-->
<!--                        </th>-->
<!--                        <tbody>-->
<!--                        <tr v-for="hotel in branchData.hotelCustomerLogList" :key="hotel.checkInDate">-->
<!--                            <td>-->
<!--                                {{hotel.checkInDate}}-->
<!--                            </td>-->

<!--                            <td>-->
<!--                                {{hotel.checkOutDate}}-->
<!--                            </td>-->

<!--                            <td>-->
<!--                                {{hotel.checkoutStatus}}-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                    <span v-for="log in hotel.roomLogs" :key="log">-->
<!--                                        {{log}}-->
<!--                                    </span>-->
<!--                            </td>-->
<!--                        </tr>-->

<!--                        </tbody>-->
<!--                    </table>-->
<!--                    <table v-if="divisionName=='Immigration'">-->
<!--                        <th class="table-header">-->
<!--                            Arrival/Departure-->
<!--                        </th>-->
<!--                        <th class="table-header">-->
<!--                            Date-->
<!--                        </th>-->
<!--                        <th class="table-header">-->
<!--                            Purpose of visit-->
<!--                        </th>-->
<!--                        <tbody>-->
<!--                        <tr v-for="immigration in branchData.immigrationCustomerLogList"-->
<!--                            :key="immigration.ArrivalDeparture">-->
<!--                            <td>-->
<!--                                {{immigration.arrivalDeparture}}-->
<!--                            </td>-->

<!--                            <td>-->
<!--                                {{immigration.dateOfArrival}}{{immigration.dateOfDeparture}}-->
<!--                            </td>-->

<!--                            <td>-->
<!--                                {{immigration.purposeOfVisit}}-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                    </table>-->
<!--                </template>-->
<!--            </v-simple-table>-->

        </v-card-text>

    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "TrackingDetails",
        props: ["index","trackType"],
        data() {
            return {
                activeComponent:"Hotel",
                branchData: [],
                divisionName: ""
            }
        },
        components: {
            'Hotel': () => import('@/modules/tracking/module/Hotel'),
            'HomeStay': () => import('@/modules/tracking/module/Hotel'),
            'Aviation': () => import('@/modules/tracking/module/Aviation'),
            'NationalPark': () => import('@/modules/tracking/module/NationalPark'),
            'Museum': () => import('@/modules/tracking/module/Museum'),
            'Pilgrimage': () => import('@/modules/tracking/module/Pilgrimage'),
            'Tourist-Arrival': () => import('@/modules/tracking/module/TouristArrival'),
            'TouristPolice': () => import('@/modules/tracking/module/TouristPolice'),
            'TouristVehicle': () => import('@/modules/tracking/module/TouristVehicle'),
            'Trekking': () => import('@/modules/tracking/module/Trekking'),
            'Adventure': () => import('@/modules/tracking/module/Adventure'),
            'Mountaineering': () => import('@/modules/tracking/module/Mountaineering'),
            'TourPackageBusiness': () => import('@/modules/tracking/module/TourPackageBusiness'),
            'TravelTour': () => import('@/modules/tracking/module/TravelTour'),
            'Income-Generation': () => import('@/modules/tracking/module/NRBExchange'),
        },
        destroyed() {
        },
        mounted() {
            this.formData()
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            formData() {
                console.log("in",this.index)
                this.divisionName = this.index.divisionName
                axios.post('Track/GetCustomerByIDsAsync', {
                    trackType:this.trackType,
                    branchID: this.index.branchID,
                    customerIDs: this.index.personinformationIDs,
                    passportNumber: this.index.passportNumber,
                    divisionName: this.index.divisionName,
                    divisionType: this.index.divisionType,
                    citizenShipNumber: this.index.citizenShipNumber
                }).then(response => {
                    this.branchData = response.data
                    this.activeComponent=this.index.divisionName
                })
            },
        }
    };
</script>

<style lang="scss" scoped>
    .table-header {
        background-color: #6495ed;
        color: white;
        font-size: 1em;
    }

    .log-table {
        border: 1px solid;
    }
</style>