<template>
    <v-content>
        <v-dialog max-width="1100px" v-model="dialog.dialogLog">
            <TrackingLog
                    :index="index"
                    :key="key.log"
                    @formResponse="onResponse"
                    v-if="dialog.dialogLog"
            />
        </v-dialog>

        <v-dialog max-width="1100px" v-model="dialog.dialogDetails">
            <TrackingDetails
                    :index="index"
                    :trackType="trackType"
                    :key="key.details"
                    @formResponse="onResponse"
                    v-if="dialog.dialogDetails"
            />
        </v-dialog>

        <v-app id="inspire">
            <v-card color="#0d4877"
                    dark>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select :items="['Passport','Citizen']"
                                      @change="clearData"
                                      :label="$t('type')"
                                      v-model="trackType">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <!--                            <v-autocomplete-->
                            <!--                                    :items="items"-->
                            <!--                                    :loading="isLoading"-->
                            <!--                                    :search-input.sync="search"-->
                            <!--                                    @input="getUserData"-->
                            <!--                                    color="white"-->
                            <!--                                    hide-no-data-->
                            <!--                                    hide-selected-->
                            <!--                                    item-text="id"-->
                            <!--                                    item-value="value"-->
                            <!--                                    label="Enter your ID"-->
                            <!--                                    placeholder="Start typing to Search"-->
                            <!--                                    prepend-icon="mdi-database-search"-->
                            <!--                                    return-object-->
                            <!--                                    v-model="model"-->
                            <!--                            ></v-autocomplete>-->
                            <v-text-field :label="$t('enter_your_id')"
                                          v-model="model"></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="12">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-2" dark small color="primary" @click="getUserData">
                                <v-icon dark> search</v-icon>
                                {{$t('search')}}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <div class="d-flex justify-center" v-if="isLoading">
                <v-progress-circular :size="50"
                                     color="primary"
                                     indeterminate></v-progress-circular>

            </div>
            <v-card class="mt-4" v-if="personDataShow">
                <v-expand-transition>
                    <v-card class="blue lighten-5">
                        <v-card-text>
                            <v-row>
                                <v-col cols="6" sm="3">
                                    Name : {{userInfo.fullName}}
                                </v-col>
                                <!--<v-col cols="6" sm="3">
                            LastName : {{userInfo.lastName}}
                        </v-col>-->
                                <v-col cols="6" sm="3">
                                    Country : {{userInfo.country}}
                                </v-col>
                                <v-col cols="6" sm="3">
                                    State : {{userInfo.state}}
                                </v-col>
                                <v-col cols="6" sm="3">
                                    Gender : {{userInfo.gender}}
                                </v-col>
                                <v-col cols="6" sm="3">
                                    Age : {{userInfo.age}}
                                </v-col>
                                <v-col cols="6" sm="3">
                                    CitizenShip Number : {{userInfo.citizenShipNumber}}
                                </v-col>
                                <v-col cols="6" sm="3">
                                    Passport Number : {{userInfo.passportNumber}}
                                </v-col>
                                <v-col cols="6" sm="3">
                                    Other ID : {{userInfo.otherID}}
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-expand-transition>
                <v-expand-transition>
                    <div class="blue lighten-5" v-if="model">
                        <v-row>
                            <v-col cols="12">



                                <!--                                <v-card color="blue lighten-4" v-for="province in userInfo.provinceList" :key="province.provinceName">-->
                                <!--                                    <v-card-title><span class="body-1"> Province Name : {{ province.provinceName}}</span></v-card-title>-->
                                <!--                                    <v-card color="blue lighten-4" v-for="division in province.divisionList" :key="division.divisionName">-->
                                <!--                                        <v-card-title><span class="body-1"> Sector Name : {{ division.divisionName}}</span></v-card-title>-->
                                <!--                                        <v-card color="blue lighten-4" v-for="branch in division.branchList" :key="branch.branchName">-->
                                <!--                                            <v-card-title><span class="body-1"> Branch Name : {{ branch.branchName}}</span></v-card-title>-->
                                <!--                                                <v-card-actions>-->
                                <!--                                                    <v-row>-->
                                <!--                                                        <v-col>-->
                                <!--                                                            <v-btn width="100%" color="blue darken-1" @click="logPopup(branch)"><span class="btn-color">logs</span></v-btn>-->
                                <!--                                                        </v-col>-->
                                <!--                                                        <v-col>-->
                                <!--                                                            <v-btn width="100%" color="blue darken-1" @click="detailPopup(branch.branchID)"><span class="btn-color">Details</span></v-btn>-->
                                <!--                                                        </v-col>-->
                                <!--                                                    </v-row>-->

                                <!--                                                </v-card-actions>-->
                                <!--                                        </v-card>-->
                                <!--                                    </v-card>-->
                                <!--                                </v-card>-->

                                <v-row>
                                    <v-col :key="division.divisionName" cols="4"
                                           v-for="division in userInfo.divisionList">
                                        <v-expansion-panels hover>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header color="blue lighten-2">
                                                    <span class="header-title-panel"> Sector Name : {{ division.divisionName}} </span>
                                                </v-expansion-panel-header>

                                                <v-expansion-panel-content>
                                                    <v-card class="mt-4"
                                                            color="blue lighten-4">
                                                        <v-card-text>
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Province</td>
                                                                            <td> Name</td>
                                                                            <td> Action</td>
                                                                        </tr>
                                                                        <tr v-for="branch in division.branchList"
                                                                            :key="branch.branchName">
                                                                            <td>{{ branch.province }}</td>
                                                                            <td>{{ branch.branchName }}</td>
                                                                            <td>
                                                                                <v-btn @click="detailPopup(branch.branchID,branch.personinformationIDs,division.divisionName,division.divisionType,userInfo)"
                                                                                       color="blue darken-1"
                                                                                       width="100%">
                                                                                    <span class="btn-color">Details</span>
                                                                                </v-btn>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>

                                                        </v-card-text>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-col>
                                </v-row>


                            </v-col>
                        </v-row>

                    </div>
                </v-expand-transition>

            </v-card>
        </v-app>
    </v-content>
</template>

<script>
    import axios from "axios";
    import TrackingLog from "./TrackingLog";
    import TrackingDetails from "./TrackingDetails";

    export default {
        name: "TrackingIndex",
        components: {
            TrackingLog, TrackingDetails
        },
        watch: {
            search(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                    if (this.trackType == "Passport") {
                        axios.get("Track/GetPassportIDListAsync", {
                            params: {
                                PassportNumber: val
                            }
                        })
                            .then(res => {
                                this.items = res.data
                            })
                            .catch(err => {
                                console.log(err)
                            })
                            .finally(() => (this.isLoading = false))
                    }
                    else {
                        axios.get("Track/GetCitizenIDListAsync", {
                            params: {
                                CitizenID: val
                            }
                        })
                            .then(res => {
                                this.items = res.data
                            })
                            .catch(err => {
                                console.log(err)
                            })
                            .finally(() => (this.isLoading = false))
                    }
                }
            },
        },
        data: () => ({
            expansion: {
                first: false,
                second: false,
            },
            personDataShow:false,
            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            search: null,
            index: {
                branchID: null,
                personinformationIDs: []
            },
            dialog: {
                dialogLog: false,
                dialogDetails: false,
            },
            items: [],
            trackType: "Passport",
            userInfo: {
                branchList: []
            },
            key: {
                log: 1,
                details: 1000,
            }
        }),
        methods: {
            onResponse() {
                this.dialog.dialogLog = false
                this.dialog.dialogDetails = false
            },
            getUserData(val) {
                this.isLoading = true
                if (val) {
                    this.userInfo = []
                    axios.get('Track/TrackTouristByID', {
                        params: {
                            ID: this.model,
                            Type: this.trackType
                        }
                    }).then(response => {
                        if(response.data){
                            this.personDataShow = true
                        this.userInfo = response.data

                        }
                    }).then(() => {
                        this.isLoading = false
                    })
                }
            },
            clearData() {
                this.model = null
                this.items = []
            },
            logPopup(val) {
                this.index = []
                this.index = val
                this.key.log++
                this.dialog.dialogLog = true
            },
            detailPopup(val, PersoninformationID, divisionName, divisionType, userInfo) {
                this.index.branchID = val
                this.index.personinformationIDs = PersoninformationID
                this.index.divisionName = divisionName
                this.index.divisionName = divisionType
                this.index.passportNumber = userInfo.passportNumber
                this.index.citizenShipNumber = userInfo.citizenShipNumber
                this.key.details++
                this.dialog.dialogDetails = true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .row {
        color: black;
    }

    .btn-color {
        color: white;
    }

    .header-title-panel {
        color: #ffffff
    }
    .v-progress-circular {
        margin: 1rem;
    }
</style>