<template>
    <v-card>
        <v-toolbar class="darken-1" color="blue" dark>
            <v-toolbar-title>
                Details
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn @click="dialogueClose" dark text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <th>
                        checkInDate
                    </th>
                    <th>
                        checkOutDate
                    </th>
                    <th>
                        checkoutStatus
                    </th>
                    <th>
                        roomList
                    </th>
                    <th>
                        roomLogs
                    </th>
                    <tbody>
                    <tr v-for="branch in branchData" :key="branch.checkInDate">
                        <td>
                            {{branch.checkInDate}}
                        </td>

                        <td>
                            {{branch.checkOutDate}}
                        </td>

                        <td>
                            {{branch.checkoutStatus}}
                        </td>

                        <td>
                            {{branch.roomList}}
                        </td>
                        <td>
              <span v-for="log in branch.roomLogs" :key="log">
                {{log}}
              </span>
                        </td>
                    </tr>

                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "TrackingDetails",
        props: ["index"],
        data() {
            return {
                branchData: []
            }
        },
        destroyed() {
        },
        mounted() {
            this.formData()
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            formData() {
                axios.post('Hotel/GetHotelCustomerByIDsAsync', {
                    branchID: this.index.branchID,
                    HotelCustomerIDs: this.index.personinformationIDs
                }).then(response => {
                    this.branchData = response.data
                })
            },
        }
    };
</script>

<style scoped>
</style>